<!--数据-->
<template>
  <div class="container data-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-capitalize">请选择数据类型</h1>
      </div>
    </div>
    <div class="dataBox">

      <div class="dateItemBox" @mouseenter="enters(1)" :style="active1">
        <div class="itemone" v-if="ItemShow1">
          <div class="imgBox">
            <img src="../../assets/img/tz.png" alt="">
          </div>
          <div class="textStyle">固废特征</div>
          <div class="EnglishText">CHARACTERISTICS OF SOLID WASTE</div>
        </div>


        <div class="dateShowBox" @mouseleave="leaver(1)" v-if="ItemShow2">
          <div class="dateShowTop">
            <div>
              <img src="../../assets/img/xtz.png" alt="">
            </div>
            <div style="margin-left: 4%;">固废特征</div>
          </div>
          <div class="textDescription">
            <span>固废特征包含固废的产生,利用与处理属性以及固废废弃物物理,化学,生物属性的数据.</span>
          </div>
          <div class="inquireBox">
            <div class="inquireBtnBox" @click="gotolink('data/WasteFeatures')">
              <div>
                <img src="../../assets/img/xcx.png" alt="">
              </div>
              <div class="dataTEXT">数据查询</div>
            </div>
          </div>
        </div>

      </div>

      <div class="dateItemBox" @mouseenter="enters(2)" :style="active2">
        <div class="itemone" v-if="ItemShow11">
          <div class="imgBox">
            <img src="../../assets/img/js.png" alt="">
          </div>
          <div class="textStyle">固废资源化技术</div>
          <div class="EnglishText">SOLID WASTE RECYCLING TECHNOLOGY</div>
        </div>

        <div class="dateShowBox" @mouseleave="leaver(2)" v-if="ItemShow22">
          <div class="dateShowTop">
            <div>
              <img src="../../assets/img/xjs.png" alt="">
            </div>
            <div style="margin-left: 4%;">固废资源化技术</div>
          </div>
          <div class="textDescription">
            <span>固废资源化技术是将固体废物经过一定的处理或加工，可使其中所含的有用物质提取出来，继续在工、农业生产过程中发挥作用，也可使有些同体废物改变形式成为新的能源或资源。</span>
          </div>
          <div class="inquireBox">
            <div class="inquireBtnBox" @click="gotolink('evaluate/evaluateData')">
              <div>
                <img src="../../assets/img/xcx.png" alt="">
              </div>
              <div class="dataTEXT">数据查询</div>
            </div>
          </div>
        </div>
      </div>

      <div class="dateItemBox" @mouseenter="enters(3)" :style="active3">
        <div class="itemone" v-if="ItemShow33">
          <div class="imgBox">
            <img src="../../assets/img/cs.png" alt="">
          </div>
          <div class="textStyle">城市垃圾</div>
          <div class="EnglishText">MUNICIPAL WASTE</div>
        </div>

        <div class="dateShowBox" @mouseleave="leaver(3)" v-if="ItemShow44">
          <div class="dateShowTop">
            <div>
              <img src="../../assets/img/xcs.png" alt="">
            </div>
            <div style="margin-left: 4%;">城市垃圾</div>
          </div>
          <div class="textDescription">
            <span>城市垃圾是城市中固体废物的混合体，包括工业垃圾，建筑垃圾和生活垃圾。工业废渣的数量，性质及其对环境污染的程度差异很大，应统一管理。</span>
          </div>
          <div class="inquireBox">
            <div class="inquireBtnBox" @click="gotolink('data/cityData')">
              <div>
                <img src="../../assets/img/xcx.png" alt="">
              </div>
              <div class="dataTEXT">数据查询</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dateItemBox" @mouseenter="enters(4)" :style="active4">
        <div class="itemone" v-if="ItemShow55">
          <div class="imgBox">
            <img src="../../assets/img/kc.png" alt="">
          </div>
          <div class="textStyle">矿产垃圾</div>
          <div class="EnglishText">URBAN MINERAL RESOURCES</div>
        </div>

        <div class="dateShowBox" @mouseleave="leaver(4)" v-if="ItemShow66">
          <div class="dateShowTop">
            <div>
              <img src="../../assets/img/xkc.png" alt="">
            </div>
            <div style="margin-left: 4%;">矿产垃圾</div>
          </div>
          <div class="textDescription">
            <span>矿产垃圾包含城市所产生的废钢、废铁、废铝、废铅、报废空调、洗衣机、电脑等数据</span>
          </div>
          <div class="inquireBox">
            <div class="inquireBtnBox" @click="gotolink('data/cityMine')">
              <div>
                <img src="../../assets/img/xcx.png" alt="">
              </div>
              <div class="dataTEXT">数据查询</div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import ui from "../../libs/ui";

export default {
  name: 'Data',
  props: {
    msg: String
  },
  data() {
    return {
      keyword: '',
      ItemShow1: true,
      ItemShow2: false,
      ItemShow11: true,
      ItemShow22: false,
      ItemShow33: true,
      ItemShow44: false,
      ItemShow55: true,
      ItemShow66: false,
      active1: '',
      active2: '',
      active3: '',
      active4: '',
      oneList: [{
        index: 1,
        images: require('../../assets/img/tz.png'),
        name: '固废特征',
        wenzi: "CHARACTERISTICS OF SOLID WASTE",
        f1: true
      },
        {
          index: 2,
          images: require('../../assets/img/js.png'),
          name: '固废资源化技术',
          wenzi: "SOLID WASTE RECYCLING TECHNOLOGY",
          f1: true
        },
        {
          index: 3,
          images: require('../../assets/img/cs.png'),
          name: '城市垃圾',
          wenzi: "MUNICIPAL WASTE",
          f1: true
        },
        {
          index: 4,
          images: require('../../assets/img/kc.png'),
          name: '城市矿产',
          wenzi: "URBAN MINERAL RESOURCES",
          f1: true
        }
      ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
    },
    go(path) {
      this.$router.push(path)
    },
    toSearch() {
      // eslint-disable-next-line no-debugger
      // debugger
      if (this.keyword === '') {
        ui.toast(this, ui.variant.primary, '请输入搜索内容')
        return;
      }
      if (this.$store.getters.token === '') {
        ui.toast(this, ui.variant.primary, '请先登录账号')
        return;
      }
      this.$router.push('/search/' + this.keyword)
    },

    // 鼠标移入
    enters(val) {
      if (val == 1) {
        this.ItemShow1 = false
        this.ItemShow2 = true
        this.active1 = 'background-color: #007EFF;'
      }
      if (val == 2) {
        this.ItemShow11 = false
        this.ItemShow22 = true
        this.active2 = 'background-color: #007EFF;'
      }
      if (val == 3) {
        this.ItemShow33 = false
        this.ItemShow44 = true
        this.active3 = 'background-color: #007EFF;'
      }
      if (val == 4) {
        this.ItemShow55 = false
        this.ItemShow66 = true
        this.active4 = 'background-color: #007EFF;'
      }

    },
    // 鼠标移出
    leaver(val) {

      if (val == 1) {
        this.ItemShow1 = true
        this.ItemShow2 = false
        this.active1 = '';
      }

      if (val == 2) {
        this.ItemShow11 = true
        this.ItemShow22 = false
        this.active2 = '';
      }
      if (val == 3) {
        this.ItemShow33 = true
        this.ItemShow44 = false
        this.active3 = '';
      }
      if (val == 4) {
        this.ItemShow55 = true
        this.ItemShow66 = false
        this.active4 = '';
      }
    },
    gotolink(name) {
      this.$router.replace(name)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../../assets/less/data.less";

.dataBox {
  width: 100%;
  height: 100%;
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .dateItemBox {
    width: 40%;
    height: 43%;
    background-color: salmon;
    border: 1px solid #EEEEEE;
    background-color: #fff;
    cursor: pointer;

    .itemone {
      width: 100%;
      height: 100%;
    }

    .imgBox {
      width: 100%;
      height: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .textStyle {
      width: 100%;
      // height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #1A1A1A;
    }

    .EnglishText {
      width: 100%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
    }
  }

  .dateShowBox {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    background-color: #007EFF;

    .dateShowTop {
      width: 100%;
      height: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      border-bottom: 1px solid #FFF;
    }

    .textDescription {
      width: 100%;
      height: 30%;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      padding-top: 2%;
      box-sizing: border-box;
    }

    .inquireBox {
      width: 100%;
      height: 35%;
      display: flex;
      justify-content: center;
      align-items: center;

      .inquireBtnBox {
        width: 160px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #007EFF;
        cursor: pointer;

        .dataTEXT {
          margin: 2% 0 0 5%;
        }
      }
    }
  }
}

.active1 {
  background-color: #007EFF !important;
}

.active2 {
  background-color: #007EFF !important;
}

.active3 {
  background-color: #007EFF !important;
}

.active4 {
  background-color: #007EFF !important;
}
</style>
